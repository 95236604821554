import React, { Component } from 'react';
import CNav from '../components/CNav';
import CFooter from '../components/CFooter';
import { blogImages } from '../utils/UConstants';

class PBlog3 extends Component {
  render() {
    return (
      <div className="bg bg_blog">
        <CNav blogEnable />
        <div className="para_container">
          <div className="para_wrapper">
            <p className="para_title">
              Healthy eating - Simple eating habits as per Ayurveda will help you live longer.
            </p>
            <p className="para_title_datetime">Dec 20, 4 min read</p>

            <div>
              <img className="para_image" src={blogImages[4 - 1]} alt="test" />
            </div>
            <div className="para_styled_container ">
              <p className="para_styled para_styled_center">
                Anyone who believes that anything can be suited to anyone is a great fool, because
                medicine is practiced not on mankind in general, but on every individual in
                particular.
              </p>
              <p className="para_styled para_styled_center">-Henri De Mondeville</p>
            </div>
            <p className="para_section">
              Good eating habits may take a while to adopt and cultivate once you decide to. But the
              amazing response that the body shows after the adoption of good eating habit will make
              you free from the regular discomfort and there is no chance of giving up it unless you
              are so careless about your health. Also there will be an urge towards continuous
              curiosity about health conscious diet.
            </p>
            <p className="para_section">
              It is clear with all developed research studies that the food that we eat has its
              effect almost on every part of our body including nails, skin and even hairs. Healthy
              eating habit prolongs our life by providing the systems and organs of our body the
              fuel they need to sustain capability to attain longevity.
            </p>
            <p className="para_section">
              On a fine note, when what we eat is healthy, do we need medicine? If you go and see
              the root, nature knows what its children needs to live. Ask your nature, see what she
              is producing on what season and cultivate the habit of eating seasonal vegetables and
              fruits to eat. It may not good to lunfollow the nature’s rules. Remember, we are part
              of nature.
            </p>
            <p className="para_section_header">Ayurvedic dietary habit</p>
            <p className="para_section">
              An Ayurvedic dietary habit that has been around from last 5000 years and more will be
              useful to those trying to make dietary changes without doing extensive research on
              types of food that provide important minerals and vitamins.
            </p>
            <p className="para_section">
              Ayurveda plans a diet in different way. Persons having same height, weight, BMI will
              have different body types accoriding to ayurevda. No individual is alike completely
              with another. So called Hi tech life of modern era cant has no answers for plenty of
              newly coming up diseases. Too many idiopaths!
            </p>
            <p className="para_section">The three factors responsible for the digestive process:</p>
            <ul>
              <li className="para_list">
                Prakriti, which is the body type and which decides what all about an individual
                journey of life with health or illness.
              </li>
              <li className="para_list">
                Intensity of digestive power, which is called as Agni in Ayurveda.
              </li>
              <li className="para_list">
                Time factor, which can be considered in different perspectives like, time taken for
                digestion, time of the day, time in season, etc.
              </li>
            </ul>
            <p className="para_section">
              The above said three factors play major role in the digestive process according to
              Ayurvedic view.
            </p>
            <p className="para_section_header">Simple rules to adopt</p>
            <ul>
              <li className="para_list">
                Hot and fresh : Warm and fresh food are easily digestible and has natural taste.
                Avoid eating cooked food that has been stored in refrigerators.
              </li>
              <li className="para_list">
                Quantity: One should consume proper quantity as per digestive power. Always
                consider, stomach must be filled in three major inputs, food, water and air. Which
                means, eat one third of your stomach capacity. Drink one third and leave one third.
                which clearly says, the digestion and mix up of food with digestive juices inside
                stomach will produce a litle gas as well which must not burp up. Over eating
                increases burden on body organs to function normally. Over pressure in the stomach,
                discomfort in stomach, flanks and chest suggests you are over eating.
              </li>
              <li className="para_list">
                Right Time: Ayurveda says the meal must be taken after the previous meal is
                digested. Frequent meal will interrupt normal complete digestion which may cause to
                stuck some undigested material on process of digestion. Having meals in proper time
                will help tyo prevent imbalance of doshas especially of digestive tract.
              </li>
              <li className="para_list">
                Right place: Your eating place must be clean and calm. Proper position is important.
                Should not eat in awkward positions like sitting in deep cushioned couch, lying down
                on bed, walking and eating, driving and eating, involving in some serious activities
                like talking loudly on the table in group, talking on phone, watching television or
                computer.
              </li>
              <li className="para_list">
                Incompatible foods: Certian foods must not be taken or consumed together which
                increases toxins in the body when taken together. Some of the foods listed are,
                Diary products and meat, Salty food with milk, Fish with yogurt or milk, Milk and
                yogurt in excess, Ice cream and yogurt in night, Non-season food. This topic will be
                discussed in detail soon as it needs a big attention.
              </li>
              <li className="para_list">
                Take your time: Dont rush to eat. system has its own rule in doing everything. One
                can not download a one gig file in just ten seconds on any modern device. It is
                rule. Human digestive system has also certain time that it takes to undergo a
                complete digestion. Don't be too lazy while eating too.
              </li>
              <li className="para_list">
                Eat only when you are hungry: One should understand the body needs. It is
                unfortunate that we listen to our motor car sounds than our own body system’s. We
                rush to our mechanic immediately if any wrong sounds break the harmony of our
                driving. But what about our body. Listening to our body is an art. One who get the
                art can achieve long life. Nothing is gifted for nothing.
              </li>
              <li className="para_list">
                Drinking water during the meals: Should not drink water immediately after the meals.
                The food is mixing up with the digestive juice which must be little acidic. Pouring
                water on top of the food may neutralize the pH which delays the digestion or may
                interrupt the digestion.
              </li>
            </ul>
            <p className="para_section_header">So, don't Live To Eat. Eat to live.</p>
          </div>
        </div>
        <CFooter blogEnable />
      </div>
    );
  }
}

export default PBlog3;
