import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import CNav from '../components/CNav';
import CFooter from '../components/CFooter';
import { clientWrapper } from '../utils/UConstants';

class PHome extends Component {
  render() {
    clientWrapper();
    return (
      <div className="bg bg_home">
        <CNav />
        <div className="page_container page_container_bottom">
          <h1 className="page_title_hdr">Kanada</h1>
          <h1 className="page_title_content">
            Dr. Kanada Narahari is an Ayurveda doctor, writer and musician. He is serving the world
            by his personal care, consultations, discourses and workshops. He is an award-winning
            writer in Kannada. Published two short story collections. He is also known for his
            column on varied field including medicine, music and yoga. He is a renowned sitar player
            having performed around the globe with world-renowned artists. He is currently based in
            Central Florida USA.
          </h1>
        </div>
        <CFooter />
      </div>
    );
  }
}

export default PHome;
