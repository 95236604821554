import React from 'react';
// import { blog1 } from '../assets/images/blog1.png';
import PropTypes from 'prop-types';

// const logo = require('../assets/images/blog1.png');

export default class CBlogList extends React.Component {
  render() {
    const { data } = this.props;
    //  alert(`data = ${JSON.stringify(data)}`);
    return (
      <div className="listitem_container direction_row">
        <img src={data.image} className="listitem_img-blog" alt="test" />
        <div className="listitem_content">
          <p className="listitem_content_title">{data.title}</p>
          <text className="listitem_content_desc">{data.description}</text>
          <div className="listitem_content_schedule">
            <p style={{ fontWeight: 400, fontSize: 12, textTransform: 'capitalize' }}>
              {data.date},
            </p>
            <p
              style={{ fontWeight: 400, fontSize: 12, textTransform: 'lowercase', marginLeft: 10 }}
            >
              {data.time}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

CBlogList.propTypes = {
  data: PropTypes.object.isRequired,
};
