import React, { Component } from 'react';
import CNav from '../components/CNav';
import CFooter from '../components/CFooter';
import { blogImages } from '../utils/UConstants';

class PBlog2 extends Component {
  render() {
    return (
      <div className="bg bg_blog">
        <CNav blogEnable />
        <div className="para_container">
          <div className="para_wrapper">
            <p className="para_title">
              Time to get up from the bed: Ambrosial hours, As per Ayurveda.
            </p>
            <p className="para_title_datetime">Nov 27, 7 min read</p>

            <div>
              <img className="para_image" src={blogImages[3 - 1]} alt="test" />
            </div>
            <div className="para_styled_container ">
              <p className="para_styled para_styled_center">Early morning has gold in its mouth!</p>
              <p className="para_styled para_styled_center">-Benjamin Franklin</p>
            </div>
            <p className="para_section">
              The most wonderful gift that ever given by ayurveda to this world is Dinacharya, which
              means healthy daily regimen. It is all bout the best possible way of leading life with
              minimal changes, which focus on balancing daily activities. Controlling bad habits,
              adopting the good ones, maintaining the good ones and uplifting quality of life
              completely is the main focus of Dinacharya. Of course the daily activity starts with
              waking up in the morning, let us discuss about waking up in the ambrosial hours:
              Brahmi muhurta. Famous Indian kannada poet Bendre says, “Morning is not just the
              sunrise.” One can only understand the predawn period and feel the divine activities of
              the surrounding by nothing but getting up in the period, wash eyes and stand in the
              terrace facing east. Now one understands why the feeling of sunrise is always stands
              immortal. what exactly lies behind the sunrise. is that which happens before the
              sunrise is just simple routine of planetary movements? No. The curiosity of thousands
              of poets, painters, artists and musicians towards the sunrise is an everlasting
              thirst. Sunrise is itself an amazing activity in this universe to witness and is
              evergreen, different everyday.
            </p>
            <p className="para_section">
              Discovering the early light of the day is something which makes to discover self.
              Acharya Vagbhata, Ayurvedic saint says in the treatise Ashtanga Hrudaya: “A healthy
              person should get up from bed in Brahmi muhurta, to protect his life!”. The great sage
              mentions that a healthy person should wake up on time, which means people who are not
              supposed to wake up are those who are suffering from the diseases of Vata and Kapha or
              people who are sick, who needs some more stretched sleeping hours.
            </p>
            <p className="para_section_header">
              Brahmi Muhurta or Ambrosial hours for self realisation
            </p>
            <p className="para_section">
              That which is related to brahma (knowledge) is called Brahmi.Muhurta is the time. As
              the commentator of Ashtanga sangraha, Arunadatta in his commentary Sarvanga sundara
              disusses about the exact time of brahmi muhurta, it is more precisely ninety six mins
              or one and a half hours before the dawn. One may picturise the brahmi muhurta as the
              period before one and a half hours of sunrise when the sky has a rosy red hue, a few
              stars may also be visible.
            </p>
            <p className="para_section_header">Why early morning or why Brahmi muhurta?</p>
            <p className="para_section">
              Health in Ayurveda mainly revolves around the principle of tridosha. Three biological
              forces, Vata, Pitta and Kapha are the responsible pillars to sustain and maintain the
              body when they are functioning in normalcy and destroy the body when they are
              abnormal. Among these three forces, Vata being principle component of Air and space,
              controls and governs the body, hence becoming the primary dosha. In this universe,
              every small movement has a law of special force of Brahma. here brahman can be
              translated as the supreme knowledge.{' '}
            </p>
            <p className="para_section">
              In the universe, early morning is dominated by vata. As Acharya Charaka says, ”
              “Vaayustantrayantradharah”, which means vata or vayu is the principle force which
              controls the living being and the mechanism of living being.
            </p>
            <p className="para_section">
              When the theory is applied to the universe. air and space are the main forces within
              which the known and unknown galaxies exist. All the material and super-material things
              existing in the body are existed in the universe and all the material and
              super-material things existing in the universe are existed in the body - Acharya
              Charaka.
            </p>
            <p className="para_section">
              The universe is dominated by all the three doshas one after the other on specific
              periods as the time and space is concerned. In a day, Kapha is dominated in the
              morning, Pitta in the mid-day and vata is the evening. In a night, Kapha in the early
              night, Pitta in midnight and Vata in the late night or in the pre-dawn period.
              Activities like, evacuation of bowel, physical exercises, studying, practising any
              special thing which require everyday practice, like practising a musical instrument
              etc can be performed very well during the Vata period, that is before sunrise; in
              Brahmi Muhurta.
            </p>
            <p className="para_section">
              Undisturbed state of vata which is found during this period is the best period of a
              twenty-four hours day and night cycle for all the practices which require everyday
              attention. To learn and realize subtler aspect of philosophies and spiritual growth of
              life, this is the period considered as the best.
            </p>
            <p className="para_section">
              Getting after sunrise imbalances Kapha dosha in the body. As the period after sunrise
              is dominated by kapha in the universe, starting the biological activity during which
              is not at all good and increases mucus activity in the body. This can be observed
              physically as an increased tendency to get respiratory infections and congestions,
              indigestion and incomplete bowel evacuation leading to the deposition of toxins deep
              in the body. One with such practice will feel lethargic, dull and stressful with
              fatigue the whole day.
            </p>
            <p className="para_section">
              Because this ambrosial period is of vata dominated, a high level of Prana (vital life
              energy) which is none other than the purest form of Vata. This prana/purest form of
              Vata which is necessary for the body to perform in its best is adequately does prevail
              the atmosphere in the brahmi muhurta.. The activity of the entire world, especially of
              living beings are yet to be started in that period and the pollution is at its minimum
              during that period. That is such a cheerful atmosphere and for the same reason, it has
              a considerable effect on body and mind.
            </p>
            <p className="para_section">
              Ayurveda gives a deep thought on the daily routine, which when followed properly
              aligns the chakras which makes one express the best of the best. The daily routine
              starts in the morning. When one starts the day in Brahmi muhurta, can achieve the best
              not only in the day but in the life on a regular practice.
            </p>
            <p className="para_section">
              Tuning our biological clock into the daily sunset and sunrise schedule is the best way
              to reverse the ageing process. Opening the eyes and staring the life of a day in
              Brahmi Muhurta puts us in a perfect synchronized manner with the natural clock. This
              microcosm, that the individual when responds properly to the law of macrocosm, will
              unfold the secret story of a successful healthy life.
            </p>
            <p className="para_section">
              Famous Sanskrit poet, Kalidasa says :Shareeramaadyam khalu dharmasaadhanam? which
              means the body is the main tool to achieve whatever we set in our life as dharma.
            </p>
            <p className="para_section">
              To keep the body updated with the newer versions of goals, one really needs a healthy
              mechanism that runs inside and directs the correct path. The activity of the universe
              which is real but unreal is because of the exhibition of Rajas and Tamas, which are
              two doshas of Manas, the universal mind. This universal mind is none other than Satva
              itself. If one looking forward to understanding the real activity of this massive
              microcosm, Brahmi muhurta is the perfect period, to begin with, where the peace never
              ends in the form of Sattva.
            </p>
            <p className="para_section">
              So, one should start the day with this auspicious feeling of supreme lightness, to
              achieve the best possibilities of the uniqueness of the being. Wake up and see once!
              It is really difficult to say no to the rising east when you witness once the glorious
              golden sky.
            </p>
          </div>
        </div>
        <CFooter blogEnable />
      </div>
    );
  }
}

export default PBlog2;
