import React, { Component } from 'react';
import Masonry from 'react-masonry-component';
import CNav from '../components/CNav';
import CFooter from '../components/CFooter';
// import CCarousel from '../components/CCarousel';
import CModal from '../components/CModal';
import { articleimgsource } from '../utils/UConstants';

const masonryOptions = {
  transitionDuration: 0,
};

const imagesLoadedOptions = { background: '.my-bg-image-el' };

class PMedia extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      srcId: '',
    };
  }

  changeState = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  updateModalImage = index => {
    //  alert(`value = ${index}`);
    this.setState({ srcId: index, showModal: true });
    this.changeState();
  };

  render() {
    const { updateModalImage } = this;

    const childElements = articleimgsource.map(function(element) {
      // alert(`element = ${JSON.stringify(element)}`);
      return (
        <li
          className="masonry_listitem"
          key={element.keyId}
          onClick={updateModalImage.bind(this, element.srcId)}
        >
          <img
            src={require(`../assets/images/${element.srcId}`)}
            className="image_carousel"
            alt=""
          />
        </li>
      );
    });
    return (
      <div className="bg bg_blog">
        <CNav blogEnable />
        <div className="masonry_container masonry_container_top">
          <Masonry
            className="my-gallery-class" // default ''
            elementType="ul" // default 'div'
            options={masonryOptions} // default {}
            disableImagesLoaded={false} // default false
            updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
            imagesLoadedOptions={imagesLoadedOptions} // default {}
            // onClick={this.changeState}
          >
            {childElements}
          </Masonry>
        </div>
        <CFooter blogEnable />
        {this.state.showModal && (
          <CModal onCloseRequest={() => this.changeState(false)}>
            <div className="imageModal">
              <img
                className="image_masonry"
                src={require(`../assets/images/${this.state.srcId}`)}
                alt="Nature"
              />
            </div>
          </CModal>
        )}
      </div>
    );
  }
}

export default PMedia;
