import React, { Component } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
// import { faAngleRight, faAngleLeft } from '@fortawesome/free-brands-svg-icons'
import CCarousel from '../components/CCarousel';
// import { ImgCard, VideoCard } from '../components/CCard';
import CNav from '../components/CNav';
import CFooter from '../components/CFooter';
import { sitaristimgsource } from '../utils/UConstants';

class PSitarist extends Component {
  render() {
    //  const { properties, property } = this.state;
    return (
      <div className="bg bg_musician">
        <CNav />
        <div className="page_container page_container_top">
          <h1 className="page_title_hdr">Sitarist</h1>
          <h1 className="page_title_content">
            Dr. Kanada Narahari is a sitar player, Indian vocalist, composer and teacher. He is well
            known for his classical, semi-classical and world music. He is the disciple of Pt
            Sanjeev Korti.
          </h1>
        </div>
        <div className="carousel_container">
          <CCarousel imgsource={sitaristimgsource} />
        </div>
        <CFooter />
      </div>
    );
  }
}

export default PSitarist;
