import React, { Component } from 'react';
// import { withRouter } from 'react-router-dom';
import CNav from '../components/CNav';
import CFooter from '../components/CFooter';
import CBlogList from '../components/CBlogList';
import { client, blogImages } from '../utils/UConstants';

class PBlogList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        /* {
          id: 1,
          image: blogImages[1 - 1],
          title: 'Importance of proper bowel evacuation in day to day life.',
          description:
            'Dinacharya in Ayurveda emphasizes on evacuation of bowels every morning. Thats all. But whys do we consider evacuating bowels in the morning? What happens if we don’t evacuate in the morning, if we skip it or if we neglect about.',
          date: 'Sep 14',
          time: '4 min read',
        }, */
        {
          id: 1,
          image: blogImages[2 - 1],
          title: 'Entertainment to Enlightenment: Naada Yoga',
          description:
            'Some time ago a sage told me while walking across a branch of Kali Gandaki river in Nepal, “Music itself is a therapy!” By then I started doing my research in the field of Indian classical music and from the knowledge I acquired, I believed that the Indian classical music is not only for the entertainment.',
          date: 'Oct 24',
          time: '6 min read',
        },
        {
          id: 2,
          image: blogImages[3 - 1],
          title: 'Time to get up from the bed: Ambrosial hours, As per Ayurveda.',
          description:
            'The most wonderful gift that ever given by ayurveda to this world is Dinacharya, which means healthy daily regimen. It is all bout the best possible way of leading life with minimal changes, which focus on balancing daily activities.',
          date: 'Nov 27',
          time: '7 min read',
        },
        {
          id: 3,
          image: blogImages[4 - 1],
          title: 'Healthy eating - Simple eating habits as per Ayurveda will help you live longer.',
          description:
            'Anyone who believes that anything can be suited to anyone is a great fool, because medicine is practiced not on mankind in general, but on every individual in particular.',
          date: 'Dec 20',
          time: '4 min read',
        },
        {
          id: 4,
          image: blogImages[5 - 1],
          title: 'Foods that should not go together',
          description:
            'Ayurvedic view of understanding every object and subject is holistic. I had a senior friend of mine in Bangalore who did his PhD in Dravyaguna (Study of medicinal herbs).',
          date: 'Jan 5',
          time: '5 min read',
        },
      ],
    };
  }

  setBlogId = (i = 0) => {
    //  e.preventDefault();
    //  alert(`value of i = ${i}`);
    //  alert(`event=${JSON.stringify(e.currentTarget.dataset.id)}`);
    //  this.props.history.push('./blog1');
    window.location.href = `${client}blog${i}`;
    /* let rejectionID = this.state.rejectionID;
   if (e.currentTarget.dataset.id == 0 || e.currentTarget.dataset.id) {
     rejectionID = e.currentTarget.dataset.id;
   }
   this.setState({ rejectionID }); */
  };

  render() {
    const { state } = this;
    return (
      <div className="bg bg_blog">
        <CNav blogEnable />
        <div className="blog_container">
          {state.data.map((data, i) => (
            <li
              style={{ listStyle: 'none' }}
              key={i}
              data-id={i}
              onClick={() => this.setBlogId(i + 1)}
            >
              <CBlogList data={data} />
            </li>
          ))}
        </div>
        <CFooter blogEnable />
      </div>
    );
  }
}

export default PBlogList;
