import React, { Component } from 'react';
// import { withRouter } from 'react-router-dom';
import CNav from '../components/CNav';
import CFooter from '../components/CFooter';
import CEventList from '../components/CEventList';
import { eventImages } from '../utils/UConstants';

class PEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
          id: 1,
          image: eventImages[1 - 1],
          title: 'The Bombay Nights 2020',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed congue, lectus ut maximus porttitor, mauris mi fringilla erat, a tincidunt dui libero eget nulla. Nullam quis justo tempus ligula faucibus tempor. ',
          date: 'Jan 1',
          time: '11.00 AM',
          venue: 'JW Marriott Hotel, Bangalore',
        },
        {
          id: 2,
          image: eventImages[2 - 1],
          title: 'Bella Ciao-NYE 2020',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed congue, lectus ut maximus porttitor, mauris mi fringilla erat, a tincidunt dui libero eget nulla. Nullam quis justo tempus ligula faucibus tempor. ',
          date: 'Feb 1',
          time: '09.30 AM',
          venue: 'Sree Kanteerava Stadium, Bangalore',
        },
        {
          id: 3,
          image: eventImages[3 - 1],
          title: 'A Night in Paris New Year Eve 2020',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed congue, lectus ut maximus porttitor, mauris mi fringilla erat, a tincidunt dui libero eget nulla. Nullam quis justo tempus ligula faucibus tempor. ',
          date: 'Mar 1',
          time: '06.15 PM',
          venue: 'Nature Adventure Camp',
        },
        {
          id: 4,
          image: eventImages[4 - 1],
          title: 'Disco Ball Nye 2020',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed congue, lectus ut maximus porttitor, mauris mi fringilla erat, a tincidunt dui libero eget nulla. Nullam quis justo tempus ligula faucibus tempor. ',
          date: 'Apr 1',
          time: '11.00 AM',
          venue: 'Loft 38, Bangalore',
        },
      ],
    };
  }

  render() {
    const { state } = this;
    return (
      <div className="bg bg_blog">
        <CNav blogEnable />
        <div className="blog_container">
          {state.data.length < 0 ? (
            state.data.map((data, i) => (
              <li style={{ listStyle: 'none', cursor: 'none' }}>
                <CEventList data={data} />
              </li>
            ))
          ) : (
            <p className="watermark">There are no events for now, please come back</p>
          )}
        </div>
        <CFooter blogEnable />
      </div>
    );
  }
}

export default PEvents;
