import React, { Component } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
// import { css } from '@emotion/core';
// import ClipLoader from 'react-spinners/ClipLoader';
import {} from '@sendgrid/mail';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CNav from '../components/CNav';
import CFooter from '../components/CFooter';
import CTextarea from '../components/CTextarea';
import CTextInput from '../components/CTextInput';
import CPhoneInput from '../components/CPhoneInput';
import CDropDown from '../components/CDropDown';
// import { authy } from '../utils/UConstants';
// import { getAuth } from '../services/AuthenticationUIController';

/* const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`; */
const rePhoneNumber = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
const validationSchema = yup.object().shape({
  first_name: yup
    .string()
    .matches(
      /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
      `Should have only alphabets, dot and space`
    )
    .required()
    .typeError('Invalid text'),

  last_name: yup
    .string()
    .matches(
      /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
      `Should have only alphabets, dot and space`
    )
    .required()
    .typeError('Invalid text'),

  email: yup
    .string()
    .label('Email')
    .email()
    .required(),
  mobile: yup
    .string()
    .matches(rePhoneNumber, 'Phone number is not valid')
    .required()
    .label('Mobile Number'),
});

class PContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      helpOption: 0,
      firstName: '',
      lastName: '',
      email: '',
      mobile: 0,
      desc: '',
      submitted: false,
      screenWidth: 0,
      inputFocussed: false,
    };
  }

  updateDimensions() {
    this.setState({ screenWidth: window.innerWidth });
    //  alert(`screenWidth = ${window.innerWidth}`);
  }

  /**
   * Add event listener
   */
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions.bind(this));
  }

  /**
   * Remove event listener
   */
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions.bind(this));
  }

  updateHelpOption = value => {
    //  alert(`received value = ${value}`);
    this.setState({ helpOption: value });
  };

  updateMobile = value => {
    //  alert(`received value = ${value}`);
    this.setState({ mobile: value });
  };

  updateDescription = value => {
    //  alert(`received value = ${value}`);
    this.setState({ desc: value });
  };

  clearFieldValues = () => {
    document.getElementById('jobtype').value = null;
    document.getElementById('firstname').value = '';
    document.getElementById('lastname').value = '';
    document.getElementById('email').value = '';
    document.getElementById('phone-form-control').value = '';
    document.getElementById('description').value = '';
  };

  notify = async () => {
    await toast('Thank you for contacting us, we shall revert back to you soon.', { delay: 1000 });
    //  this.setState({ firstName: '', lastName: '', email: '', mobile: 0, desc: '' });
    setTimeout(() => {
      this.clearFieldValues();
    }, 3000);
  };

  inputFocussed = async () => {
    //  alert(`inputfocussed`);
    //  e.preventDefault();
    const newState = Object.assign({}, this.state);
    newState.inputFocussed = this.state.inputFocussed === false;
    await this.setState(newState);

    //  await this.setState({ inputfocussed: !this.state.inputFocussed });
    //  alert(`inputFocussed = ${this.state.inputFocussed}`);
  };

  render() {
    return (
      <div className="bg bg_contact">
        {this.state.screenWidth < 400 && this.state.inputFocussed === true ? null : (
          <CNav blogEnable />
        )}
        <div className="contact_page_container">
          <div className="contact_page_container_contact">
            <p className="para_bigboldest">Contact Us</p>
            <hr
              style={{
                color: 'black',
                backgroundColor: 'black',
                opacity: 0.16,
                height: 3,
                width: 500,
              }}
            />
            <p className="para_bold space_top_large">
              Or fill out form, we'll quickly get back to you.
            </p>
          </div>
          <div className="contact_form">
            <Formik
              initialValues={{ first_name: '', last_name: '', email: '', desc: '' }}
              validationSchema={validationSchema}
              onSubmit={async (values, actions) => {
                this.notify();
                // todo: Send email here once form submitted
                //  alert(`Values = ${JSON.stringify(values)}`);
                /* alert(
                  `HelpOption = ${this.state.helpOption} FirstName = ${
                    values.first_name
                  }, LastName = ${values.last_name}, Email = ${values.email}, Mobile = ${
                    this.state.mobile
                  } Description = ${this.state.desc}`
                ); */
                // todo install sengrid npm package and call the simple api to send mail
                /* const sgMail = require('@sendgrid/mail');
                //  alert(`sgMail = ${JSON.stringify(sgMail)}`);
                //  API Key = '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'
                //  sgMail.setApiKey(process.env.SENDGRID_API_KEY);
                sgMail.setApiKey(
                  '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'
                );
                const msg = {
                  to: 'sunil.samsung@gmail.com',
                  // to: 'dr.kanada@gmail.com',
                  from: 'priyanka.ks@gmail.com',
                  // from: this.state.email,
                  subject: 'Sending with Twilio SendGrid is Fun',
                  text: 'testing contcat page description',
                  // text: this.state.desc,
                  html: '<strong>testing contcat page description</strong>',
                };
                //  sgMail.send(msg);
                sgMail
                  .send(msg)
                  .then(() => {
                    alert('success');
                  })
                  .catch(error => {
                    alert(error.toString());
                    // Log friendly error
                    // eslint-disable-next-line no-restricted-syntax
                    console.error(error.toString());

                    // Extract error msg
                    const { message, code, response } = error;

                    // Extract response msg
                    const { headers, body } = response;
                  }); */
              }}
            >
              {formikProps => (
                <React.Fragment>
                  <div className="contact_form_leftaligned">
                    <div className="contact_dropdown">
                      <CDropDown
                        label="How can we help?"
                        func={this.updateHelpOption}
                        id="jobtype"
                        inputFocussed={this.inputFocussed}
                      />
                    </div>
                    <div className="contact_rows">
                      <CTextInput
                        label="First Name"
                        formikProps={formikProps}
                        formikKey="first_name"
                        placeholder=""
                        inputFocussed={this.inputFocussed}
                        id="firstname"
                      />
                      <CTextInput
                        label="Last Name"
                        formikProps={formikProps}
                        formikKey="last_name"
                        placeholder=""
                        inputFocussed={this.inputFocussed}
                        id="lastname"
                      />
                    </div>
                    <div className="contact_rows">
                      <CTextInput
                        label="Email"
                        formikProps={formikProps}
                        formikKey="email"
                        placeholder=""
                        inputFocussed={this.inputFocussed}
                        id="email"
                      />
                      <CPhoneInput
                        label="Mobile"
                        formikKey="mobile"
                        func={this.updateMobile}
                        placeholder="Your Mobile number"
                        id="phone"
                      />
                    </div>
                    <div className="contact_textarea">
                      <CTextarea
                        label="Description"
                        placeholder="Please write the details here"
                        func={this.updateDescription}
                        inputFocussed={this.inputFocussed}
                        id="description"
                      />
                    </div>
                  </div>
                  {
                    <React.Fragment>
                      <button
                        type="submit"
                        className="contact_button"
                        onClick={() => {
                          this.notify();
                          //   resetForm({});
                        }}
                      >
                        Submit
                      </button>
                      <text
                        style={{
                          color: 'red',
                          textAlign: 'justify',
                          marginBottom: 10,
                        }}
                      >
                        {formikProps.errors.general}
                      </text>
                    </React.Fragment>
                  }
                </React.Fragment>
              )}
            </Formik>
          </div>
        </div>
        <ToastContainer />
        {this.state.screenWidth < 400 && this.state.inputFocussed === true ? null : (
          <CFooter blogEnable />
        )}
      </div>
    );
  }
}

export default PContact;
