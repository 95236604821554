import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/SIndex.scss';

function CNav(props) {
  const params = { props };
  return (
    <nav className={`ovrly ${params.props.blogEnable ? 'ovrly_hdr ovrly_hdr_blog' : 'ovrly_hdr'}`}>
      <Link to="/" className="navStyle navStyle_hdr">
        <h1
          className={`navStyle navStyle_icon ${params.props.blogEnable ? 'navStyle_blog' : null}`}
        >
          kanada.co
        </h1>
      </Link>
      <ul className="navlinks navlinks_hdr">
        <Link
          to="/sitarist"
          className={`navStyle navStyle_hdr ${params.props.blogEnable ? 'navStyle_blog' : null}`}
        >
          <li> Musician </li>
        </Link>
        <Link
          to="/ayurveda"
          className={`navStyle navStyle_hdr ${params.props.blogEnable ? 'navStyle_blog' : null}`}
        >
          <li>Ayurveda</li>
        </Link>
        <Link
          to="/author"
          className={`navStyle navStyle_hdr ${params.props.blogEnable ? 'navStyle_blog' : null}`}
        >
          <li>Author</li>
        </Link>
      </ul>
    </nav>
  );
}

export default CNav;
