import React, { Component } from 'react';
import CNav from '../components/CNav';
import CFooter from '../components/CFooter';
import { blogImages } from '../utils/UConstants';

class PBlog1 extends Component {
  render() {
    return (
      <div className="bg bg_blog">
        <CNav blogEnable />
        <div className="para_container">
          <div className="para_wrapper">
            <p className="para_title">Entertainment to Enlightenment - Naada Yoga.</p>
            <p className="para_title_datetime">Oct 24, 6 min read</p>

            <div>
              <img className="para_image" src={blogImages[2 - 1]} alt="test" />
            </div>
            <p className="para_section_header">Music therapy</p>
            <p className="para_section">
              Some time ago a sage told me while walking across a branch of Kali Gandaki river in
              Nepal, “Music itself is a therapy!” By then I started doing my research in the field
              of Indian classical music and from the knowledge I acquired, I believed that the
              Indian classical music is not only for the entertainment. Over the period, learning
              Indian classical music on sitar, studying all the possible structures available for
              reach at that time and listening to the most of available maestro works, I reached to
              the state of the pure theta waves from where I started approaching my clients/patients
              with music therapy.
            </p>
            <p className="para_section_header">Music therapy in my practice</p>
            <p className="para_section">
              Here, the musical instrument used is Sitar, and its strings have the property to
              unbind one’s mind from the stress and related issues. The history of music therapy and
              Indian raga therapies proved its benefits in many psychosomatic disorders. Various
              techniques of meditations, when combined with Indian classical music renditions of
              sitar, has greater benefits in the treatments of such psychosomatic disorders. Whether
              the world of medicine knew or not, from the beginning time of modern medicine, no
              doctor said that one can cure all the diseases. But making one’s life better is the
              humble attempt of all genuine minds of the east and west. It’s indeed true that music
              therapy promotes general inner wellbeing and helps in increasing spiritual awareness
              for most of the people.
            </p>
            <p className="para_section_header">How unique is music therapy?</p>
            <p className="para_section">
              With the everyday stress and worries of the modern-day life, we are all victims of its
              side effects. I am trying to present this innovative form of Yoga which involves Deep
              Meditation with the focus on Live rendition of different “Raagas – moods” on Sitar,
              which is a well known Indian Classical Instrument.
            </p>
            <p className="para_section">
              It is proved with all the researches done by modern scientific societies that
              different Raagas have their effect on treating different psychological and
              psychosomatic conditions. It is also proved scientifically that every Raaga has its
              own subtle effect on the conscious of the living beings in the universe. So music
              therapy is getting its academic importance day by day from the past century.
            </p>
            <p className="para_section">
              But apart from its academic plot, Indian classical music has a structure-less divine
              form, where countless musicians worked like saints, worshiped and liberated from this
              material world. Soofism, various bhakti panths, are all philosophies of a beautiful
              musical journey of life.
            </p>
            <p className="para_section">
              But in instrumental classical music, there are no verbal communication between the
              performer and the listener. There is only purest form of music which are the
              combination of 7 swaras-notes. They have their direct effect on sapta chakras of the
              body.
            </p>
            <p className="para_section">
              There is no single piece knowledge in this world which is not gifted by the ancient
              sages of Indian civilization. Seven basic notes of Indian classic musichave their
              origin in Veda mantras and Yoga is the the conjunction of human spirit with the
              supreme soul. So the supreme Nadayoga!
            </p>
            <p className="para_section_header">Raagas and chakras</p>
            <p className="para_section">
              Ragas of Indian classical music are though designed basically to express different
              emotions, ultimately the action that takes place inside is by helping to activate
              specific chakras, which allows the Kundalini energy to rise easily and energize and
              nourish the chakra. The raga also influences the chakra to maintain its optimum spin
              and balance, ensuring a balanced energy supply to different organs that are connected
              to the specific chakra. we consider some of the examples that we find from the works
              of research scholars around.
            </p>
            <div className="para_styled_container ">
              <p className="para_styled para_styled_center para_styled_lineheight">
                '' The raga Gurjari Todi, has a capacity to cool down the Liver. This raga and the
                raga Yaman help activate the Swadishthan chakra that governs our attention. Both
                ragas help focus wandering or wavering attention, which is crucial for effective
                meditation. These ragas also develop the quality of the Fire Element and make our
                personality creative, balanced, closely attentive and spiritual.''
              </p>
            </div>
            <p className="para_section">
              The ragas Bhairav and Durga have a power of Divine bliss and protection. Both help
              activate the Anahat chakra. When the Kundalini touches the heart chakra, Raga Bhairav
              activates spirituality in the person. Raga Durga boosts self-confidence and helps
              develop the quality of the air element. These ragas also enhance the Divinity and
              Immunity in children. The Raga Jaijaiwanti helps activate the Vishuddhi chakra, the
              controller of the sensory organs. This raga also develops the quality of the ether
              element and the expression of voice, and helps make one’s personality loving and
              sweet.
            </p>
            <p className="para_section">
              The Raga Bhup (Mohanam in Carnatic) helps purify and open the Agnya chakra. It helps
              relieve tensions, anger and mental fatigue. The mood created by this Raga helps the
              Kundalini pass through the Agnya chakra and enter the Sahasrara in the limbic area of
              the brain. This causes the person to reach a state of thoughtless awareness and has a
              tremendous impact on our ability to forgive.
            </p>
            <p className="para_section">
              Ragas Darbari (Darbari Kaanada in Carnatic) and Bhairavi (Sindhu Bhairavi in Carnatic)
              are helpful in prolonging the state of meditation and thoughtless awareness. The notes
              of these ragas help relax and calm the emotionally-related limbic area. The Kundalini
              energy then soothes and nourishes the Sahasrara chakra and the brain. The result is
              that one feels, joyous, energetic, peaceful and relieved of tension and depression.
              Aspirants of yoga also enjoy the sensation of a cool breeze on the finger tips and
              achieves the state of Self-Realization or enlightenment.” Indian system of music makes
              such a great connection between the microcosm and macrocosm where the great secrecy of
              ultimate nature unbinds on its own on a regular deep saadhana or riyaz or practice. If
              one listens properly, the whole world has a lay and swar. Different swaras signifies
              different nature of activities around. Midnight’s mood has a different laya, mid-day
              has another and a morning may differ in its laya.
            </p>
            <p className="para_section">
              Being one with the universe and enjoying the Devine association of inner being is the
              ultimate goal of any form of Yoga, and thus Naada Yoga.
            </p>
          </div>
        </div>
        <CFooter blogEnable />
      </div>
    );
  }
}

export default PBlog1;
