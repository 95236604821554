import * as React from 'react';
import PropTypes from 'prop-types';

export default class CTextarea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
    };
  }

  handleChange = event => {
    //  const { data } = this.props;
    //  const { state } = this.state;

    this.setState({ text: event.target.value }, () => {
      // alert(`value = ${this.state.text}`);
      this.props.func(this.state.text);
    });
  };

  render() {
    //  const { data } = this.props;
    //  const { state } = this.state;

    return (
      <div className="ti_container">
        <label htmlFor="test" className="ti_label">
          {this.props.label}
        </label>

        <textarea
          id={this.props.id}
          value={this.state.text}
          onChange={this.handleChange}
          className="ti_textarea"
          placeHolder={this.props.placeholder}
          onFocus={() => (this.props.inputFocussed ? this.props.inputFocussed() : null)}
          onBlur={() => (this.props.inputFocussed ? this.props.inputFocussed() : null)}
        />
      </div>
    );
  }
}

CTextarea.propTypes = {
  data: PropTypes.object.isRequired,
};
