import React from 'react';
// import { blog1 } from '../assets/images/blog1.png';
import PropTypes from 'prop-types';

// const logo = require('../assets/images/blog1.png');

export default class CEventList extends React.Component {
  render() {
    const { data } = this.props;
    return (
      <div className="listitem_container direction_column">
        <div className="listitem_img-event" />

        <div className="listitem_content">
          <p className="listitem_content_title">{data.title}</p>
          <div className="listitem_content_schedule">
            <p
              style={{
                marginLeft: 0,
                fontWeight: 600,
                fontSize: 12,
                textDecoration: 'capitalize',
              }}
            >
              {data.date},
            </p>
            <p
              style={{
                marginLeft: 0,
                fontWeight: 600,
                fontSize: 12,
                textDecoration: 'lowercase',
              }}
            >
              {data.time}
            </p>
          </div>
          <text className="listitem_content_desc">
            <text style={{ fontWeight: 600, color: '#000000a0' }}>Venue: </text>
            {data.venue}
          </text>
        </div>
      </div>
    );
  }
}

CEventList.propTypes = {
  data: PropTypes.object.isRequired,
};
