import * as React from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import PropTypes from 'prop-types';

import 'react-phone-input-2/lib/style.css';
// import 'react-phone-input-2/lib/material.css';
// import 'react-phone-input-2/lib/plain.css';

export default class CPhoneInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: '',
    };
  }

  handleOnChange = value => {
    //  const { data } = this.props;

    this.setState({ phone: value }, () => {
      this.props.func(value);
    });
  };

  render() {
    //  const { data } = this.props;
    //  const { state } = this.state;

    return (
      <div className="ti_container">
        <label htmlFor="test" className="ti_label">
          {this.props.label}
        </label>

        <ReactPhoneInput
          inputExtraProps={{
            name: 'phone',
            required: true,
            autoFocus: true,
          }}
          country="us"
          id={this.props.id}
          value={this.state.phone}
          onChange={this.handleOnChange}
          containerStyle={{ maxWidth: 200 }}
          inputStyle={{ maxWidth: 200 }}
        />
      </div>
    );
  }
}

CPhoneInput.propTypes = {
  data: PropTypes.object.isRequired,
};
