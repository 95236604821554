import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCoffee } from '@fortawesome/free-solid-svg-icons';

/* function openTab() {
  window.open('https://www.facebook.com/kanadanarahari/');
} */

function CFooter(props) {
  const params = { props };
  return (
    <nav className={`ovrly ${params.props.blogEnable ? 'ovrly_ftr ovrly_ftr_blog' : 'ovrly_ftr'}`}>
      <ul className="navlinks navlinks_ftr_large navlinks_paddingleft font_smallest">
        <Link
          to="/blog"
          className={`navStyle navStyle_ftr display_column align_items_center ${
            params.props.blogEnable ? 'navStyle_blog' : null
          }`}
        >
          <FontAwesomeIcon icon={['fas', 'blog']} />
          <text className="font_tiny padding_top_smallest fontweight_thin">Blog</text>
        </Link>
        <Link
          to="/contact"
          className={`navStyle navStyle_ftr display_column align_items_center ${
            params.props.blogEnable ? 'navStyle_blog' : null
          }`}
        >
          <FontAwesomeIcon icon={['fas', 'address-book']} />
          <text className="font_tiny padding_top_smallest fontweight_thin">Contact</text>
        </Link>
        <Link
          to="/events"
          className={`navStyle navStyle_ftr display_column align_items_center ${
            params.props.blogEnable ? 'navStyle_blog' : null
          }`}
        >
          <FontAwesomeIcon icon={['fas', 'calendar-alt']} />
          <text className="font_tiny padding_top_smallest fontweight_thin">Events</text>
        </Link>
        <Link
          to="/media"
          className={`navStyle navStyle_ftr display_column align_items_center ${
            params.props.blogEnable ? 'navStyle_blog' : null
          }`}
        >
          <FontAwesomeIcon icon={['fas', 'newspaper']} />
          <text className="font_tiny padding_top_smallest fontweight_thin">Media</text>
        </Link>
      </ul>
      <ul className="navlinks navlinks_ftr">
        <Link
          target="_blank"
          //  to="https://www.facebook.com/kanadanarahari/"
          onClick={() => window.open('https://www.facebook.com/kanadanarahari/')}
          className={`navStyle navStyle_ftr ${params.props.blogEnable ? 'navStyle_blog' : null}`}
        >
          <FontAwesomeIcon icon={['fab', 'facebook-f']} />
        </Link>
        <Link
          target="_blank"
          onClick={() => window.open('https://www.instagram.com/sitar_kanada/')}
          className={`navStyle navStyle_ftr ${params.props.blogEnable ? 'navStyle_blog' : null}`}
        >
          <FontAwesomeIcon icon={['fab', 'instagram']} />
        </Link>
      </ul>
    </nav>
  );
}

export default CFooter;
