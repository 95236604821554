import React, { PureComponent } from 'react';
import { LightgalleryProvider, LightgalleryItem } from 'react-lightgallery';
import Carousel from 'react-multi-carousel';
import PropTypes from 'prop-types';
// import { client } from '../utils/UConstants';
// import music1 from '../assets/images/music1.png';

import 'react-multi-carousel/lib/styles.css';
// import imgsource from '../utils/UConstants';

/* const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}; */

const PhotoItem = ({ image, group }) => {
  // eslint-disable-next-line no-restricted-syntax
  console.log(`src = ${image} group = ${group}`);
  return (
    <div className="container">
      <LightgalleryItem
        group={group}
        src={
          image.type === 1
            ? `https://www.youtube.com/watch?v=${image.srcId}`
            : require(`../assets/images/${image.srcId}`)
        }
      >
        <img
          src={
            image.type === 1
              ? `https://img.youtube.com/vi/${image.srcId}/0.jpg`
              : require(`../assets/images/${image.srcId}`)
          }
          className={image.type === 1 ? `image` : 'img'}
          alt=""
        />
        {image.type === 1 ? (
          <button className="overlay" type="button">
            <div className="text">
              {/* <img className="video_icon" src="https://sachinchoolur.github.io/lightgallery.js/static/img/zoom.png" alt="" /> */}
              <img
                src="https://img.icons8.com/material-rounded/40/FFFFFF/circled-play.png"
                alt=""
              />
            </div>
          </button>
        ) : null}
      </LightgalleryItem>
    </div>
  );
};

/* function CustomRightArrow(onClick, ...rest) {
  const {
    onMove,
    state: { currentSlide, deviceType },
  } = rest;
  // onMove means if dragging or swiping in progress.
  return <button onClick={() => onClick()} type="submit" />;
} */

class CCarousel extends PureComponent {
  render() {
    const { imgsource } = this.props;
    //  alert(`array = ${JSON.stringify(imgsource)}`);
    return (
      <Carousel
        additionalTransfrom={0}
        arrows
        //   autoPlay
        autoPlaySpeed={3000}
        centerMode
        className=""
        containerClass="container-with-dots"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite
        itemClass=""
        // keyBoardControl
        minimumTouchDrag={80}
        renderButtonGroupOutside
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024,
            },
            items: 5,
            // partialVisibilityGutter: 40,
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0,
            },
            items: 1,
            // partialVisibilityGutter: 30,
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464,
            },
            items: 2,
            // partialVisibilityGutter: 30,
          },
        }}
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        //   customRightArrow={<CustomRightArrow />}
        swipeable
        partialVisible={false}
      >
        {imgsource.map((p, idx) => {
          // eslint-disable-next-line no-restricted-syntax
          console.log(`p = ${JSON.stringify(p)}`);
          return (
            <LightgalleryProvider
              onAfterSlide={() => {
                // eslint-disable-next-line no-restricted-syntax
                console.log('onAfterSlide');
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <PhotoItem image={p} group="group1" />
              </div>
            </LightgalleryProvider>
          );
        })}
      </Carousel>
    );
  }
}

PhotoItem.propTypes = {
  image: PropTypes.object.isRequired,
  group: PropTypes.object.isRequired,
};
CCarousel.propTypes = {
  imgsource: PropTypes.object.isRequired,
};
export default CCarousel;
