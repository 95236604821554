import * as React from 'react';
import Select from 'react-select';
import { helpOptions } from '../utils/UConstants';

/* const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px dotted pink',
    borderTop: '1px dotted pink',
    borderLeft: '1px dotted pink',
    borderRight: '1px dotted pink',
    color: state.isSelected ? 'red' : 'blue',
    padding: 20,
    width: 500,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: 200,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
  container: () => ({ width: 300 }),
}; */
export default class CDropDown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
    };
  }

  handleChange = selectedOption => {
    const { props } = this;
    this.setState({ selectedOption }, () => {
      props.func(selectedOption);
    });
  };

  render() {
    const { selectedOption } = this.state;
    const { props } = this;
    return (
      <div className="ti_container">
        <label htmlFor="test" className="ti_label">
          {props.label}
        </label>
        <Select
          id={this.props.id}
          value={selectedOption}
          onChange={this.handleChange}
          options={helpOptions}
          onFocus={() => (this.props.inputFocussed ? this.props.inputFocussed() : null)}
          onBlur={() => (this.props.inputFocussed ? this.props.inputFocussed() : null)}
        />
      </div>
    );
  }
}
