import React, { Component } from 'react';
import CNav from '../components/CNav';
import CFooter from '../components/CFooter';
import { blogImages } from '../utils/UConstants';

class PBlog4 extends Component {
  render() {
    return (
      <div className="bg bg_blog">
        <CNav blogEnable />
        <div className="para_container">
          <div className="para_wrapper">
            <p className="para_title">Foods that should not go together - “Viruddhaahara”</p>
            <p className="para_title_datetime">Jan 5, 5 min read</p>

            <div>
              <img className="para_image" src={blogImages[5 - 1]} alt="test" />
            </div>
            <p className="para_section">
              Ayurvedic view of understanding every object and subject is holistic. I had a senior
              friend of mine in Bangalore who did his PhD in Dravyaguna (Study of medicinal herbs).
              When I aproach him with any queries on a unidentified medicinal plant he would start
              in this manner: This world is Panchbhoutik (made up of five basic elements). This
              plant, because it is born in this material world is also Panchbhoutik!
            </p>
            <p className="para_section">
              Whenever I approach him for any queries, he would speak in the same manner. I used to
              feel awkward! I am asking a direct question. Why this professor is messing up the
              topic with basic principles? But I used to keep quite myself and pretend to listen to
              what he says.
            </p>
            <p className="para_section">
              Then he continues. This pant is derived from this family and so and so. This plant is
              more sweet in (Rasa)taste, heavy in (Guna) quality, Cold in (Veerya) potency and its
              after digestion effect (Vipaka) is sweet.
            </p>
            <p className="para_section">
              Then he come back to the panchabhoutik theory (five elements theory) and explain why
              this plant cures these diseases etc with the elements of panchabhoutic composition.
            </p>
            <p className="para_section">
              Every part and particle in the world has some or other medicinal value! – Acharya
              Charak. That may be of botanical origin or mineral. Animal origin or something else.
            </p>
            <p className="para_section">
              We can understand a whole picture of the verse of acharya and adopt the principle in
              every steps we take in the preparation of our food and combining food articles during
              the preparation and consuming. As per ayurveda, action of any edible matter is
              depending on these five general properties. They are Rasa- Taste, Guna- quality,
              Veerya- Energy, Vipaka- Post digestive effect and the last one is Prabhava –
              Unexplained effect, whereas the last one is subjective.
            </p>
            <p className="para_section">
              When the various foods which possess opposite qualities combine together and go
              through the digestive process, Agni – the digestive factor gets diminished or
              suppressed or may go wild!. This phenomenon leads to the development of toxins in the
              form of Aama. Though different incompatible food can also be digested easily in people
              with strong digestive factor, the strength of Agni in them is not constant and there
              are various factors around which may hamper anyone’s Agni and lead to the production
              of toxins at different stages. This improper combination will undergo abnormal
              digestion producing fermented juices in the stomach leading to the production of gas.
              When this improper combination is continued, back to back production of Ama-Digestive
              toxins will take place and finally, the man will be sick, get sick often or/and when
              neglected on each such cycles of sickness will lead to the permanent sickness. All the
              lifestyle disorders like hypertension, diabetes mellitus, hyperlipidemia etc are the
              examples. Also, we can consider autoimmune conditions like SLE, Psoriasis etc in the
              same category. To be honest with my opinion most of the disease of mankind has the
              roots in improper Agni.
            </p>
            <p className="para_section">
              In this modern era, we find the digestive biscuits and indigestion pills are available
              on same shelves of a supermarket. But, if one dont understand the properties of food
              article that we combine in our daily food and continues to consume improper
              combinations everyday with taking digestive pills, what is the use? It is like keeping
              a tender plant under bright summer sunlight and watering its leaves throughout the
              day!
            </p>
            <p className="para_section">Strawberry milkshake: A classic example of Viruddhahara</p>
            <p className="para_section">
              For example, let us order a strawberry milkshake. Put the ingredients as simple, cold
              milk, sugar and refrigerated berries. Consider the properties of each. Milk is heavy
              in quality. Sugar and berries are light. Milk and sugar are sweet but berries have
              sweet and sour taste. After effect, the vipaka of milk is sweet but of berries would
              be Sour. Because of the Amla vipaka (sour – post digestive effect), berries possess
              heat but milk will be cold! This combination may produce toxins in the form of Ama,
              leading to allergic reactions. Now how do you feel this combination would be good?
              World’s most famous and lovely milkshake is found to be an incompatible production
              now!
            </p>

            <p className="para_section">
              Similarly, papaya milkshakes are worst! Eating liquid Jaggery with drinking sugar cane
              juice is again another big story. Liquid jaggery is laxative and sugarcane juice is a
              diuretic!{' '}
            </p>
            <p className="para_section">
              Let us follow some simple guides and rules that will simplify the concept of improper
              combination. It may look quite difficult to understand the subject with the background
              of basic principles. But nothing is difficult when you understand the concepts on some
              basic rules. These following rules will slowly enlighten your knowledge on the subject
              as you feel the food articles that you eat and taste, give time to think about what
              you eat and what the food tastes.
            </p>
            <p className="para_section">Facts to consider when combining food</p>
            <p className="para_section">
              Agni: Good digestive fire can combat the possible bad effect of improper food
              combination. Agni must be protected well in order to face the probable complications
              of bad food combination.
            </p>
            <p className="para_section">
              Time and season: Seasons and different time of a day has effect on digestive system
              via increasing or decreasing agni.
            </p>
            <p className="para_section">
              Spices and herbs: Ayurveda cooking promotes in the perfect balanc of spices and herbs
              in food. The properties of herbs and spices helps in alleviating the toxins of
              incompatible combinations of food. For example, Cumin and ginger in heav food.
            </p>
            <p className="para_section">
              Oka Satmya: Nothing is universal. Bad habbits of eating, like everyday potato or meat
              may be accustomed to few people’s digestive system. It may be not advised to continue
              such practices, but as per the general rule: this is bad, the bad effect of a
              particular bad combination or habit may not be so bad in the people with such
              practice. Its all about training the system. We see people can be very easily migrate
              from non-vegetarianism to vegetarianism. But it is difficult for a vegetarian to
              become non-vegetarian in middle age.
            </p>
            <p className="para_section">
              Not everyday: Again bad combination is subjective. here and there in forced party
              sharties, bad combinations of food will happen. No choice. But the same practice
              everyday is not advisable.
            </p>
            <p className="para_section">Certian examples for – Not good combination:</p>
            <ul>
              <li className="para_list">
                Fruits: Generally fruit salad would be a bad idea. banana has different post
                digestive effect than Mango’s. Berries are different in in properties than Cherries.
                It is generally adviced to have fruits individually. In casse of mixing different
                fruits making salads, adding cinnamon powder may alleviate the post digestive
                toxins.
              </li>
              <li className="para_list">
                Honey: Must not be heated. And must not be mixed in equal quantity with butter ghee.
              </li>
              <li className="para_list">Milk: with banana and citric fruits. with meat, fish.</li>
              <li className="para_list">Yogurt: with other diary products.</li>
            </ul>
            <p className="para_section">
              There are hundreds of example can be made on the qualities of different foods
              considered. But it will be very wise if one understand the basic principles of the
              practically advised concept of -Viruddhaahaara – Improper combination of food, as
              discussed in the beginning of this article.
            </p>
          </div>
        </div>
        <CFooter blogEnable />
      </div>
    );
  }
}

export default PBlog4;
