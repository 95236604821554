import * as React from 'react';
import PropTypes from 'prop-types';

const CTextInput = ({ label, formikProps, formikKey, ...rest }) => {
  const params = { ...rest };
  const borderError = false;

  return (
    <div className="ti_container">
      <label htmlFor="test" className="ti_label">
        {label}
      </label>
      <input
        type="input"
        name="test"
        id={params.id}
        className="ti_field"
        placeHolder={params.placeholder ? params.placeholder : null}
        onChange={formikProps.handleChange(formikKey)}
        onBlur={() => (params.inputFocussed ? params.inputFocussed() : null)}
        borderError={borderError}
        onFocus={() => (params.inputFocussed ? params.inputFocussed() : null)}
      />
    </div>
  );
};

CTextInput.propTypes = {
  label: PropTypes.string.isRequired,
  formikProps: PropTypes.any.isRequired,
  formikKey: PropTypes.any.isRequired,
  // eslint-disable-next-line react/require-default-props
  letterSpacing: PropTypes.number,
};

export default CTextInput;
