import React, { Component } from 'react';
// import Background from '../assets/background/web_doctor@2x.png';
import CNav from '../components/CNav';
import CFooter from '../components/CFooter';
import CCarousel from '../components/CCarousel';
import { ayurvedaimgsource } from '../utils/UConstants';

class PAyurveda extends Component {
  render() {
    return (
      <div className="bg bg_doctor">
        <CNav />
        <div className="page_container page_container_top">
          <h1 className="page_title_hdr">Ayurveda</h1>
          <h1 className="page_title_content">
            Dr. Kanada Narahari is a qualified Ayurveda doctor with BAMS degree in 2007. Since then
            he is teaching Ayurveda and treating clients worldwide. His neoclassical approach
            towards health is appreciated.
          </h1>
        </div>
        <div className="carousel_container">
          <CCarousel imgsource={ayurvedaimgsource} />
        </div>

        <CFooter />
      </div>
    );
  }
}

export default PAyurveda;
