import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  faBeer,
  faCoffee,
  faWineGlassAlt,
  faStroopwafel,
  faAppleAlt,
  faCookie,
  faBlog,
  faAddressBook,
  faCalendarAlt,
  faNewspaper,
} from '@fortawesome/free-solid-svg-icons';
// import logo from './logo.svg';
import PHome from './pages/PHome';
import PSitarist from './pages/PSitarist';
import PAyurveda from './pages/PAyurveda';
import PAuthor from './pages/PAuthor';
import PBlogList from './pages/PBlogList';
import PEvents from './pages/PEvents';
import PContact from './pages/PContact';
import PBlog1 from './pages/PBlog1';
import PBlog2 from './pages/PBlog2';
import PBlog3 from './pages/PBlog3';
import PBlog4 from './pages/PBlog4';
// import PBlog5 from './pages/PBlog5';
// import PBlog from './pages/PBlog';
import PMedia from './pages/PMedia';

library.add(
  fab,
  faBeer,
  faCoffee,
  faWineGlassAlt,
  faStroopwafel,
  faAppleAlt,
  faCookie,
  faBlog,
  faAddressBook,
  faCalendarAlt,
  faNewspaper
);
function App() {
  return (
    <Router>
      <div>
        <Route path="/" component={PHome} exact />
        <Route path="/sitarist" component={PSitarist} />
        <Route path="/ayurveda" component={PAyurveda} />
        <Route path="/author" component={PAuthor} />
        <Route path="/blog" component={PBlogList} />
        <Route path="/events" component={PEvents} />
        <Route path="/contact" component={PContact} />
        <Route path="/blog1" component={PBlog1} />
        <Route path="/blog2" component={PBlog2} />
        <Route path="/blog3" component={PBlog3} />
        <Route path="/blog4" component={PBlog4} />
        {/* <Route path="/blog5" component={PBlog5} /> */}
        <Route path="/media" component={PMedia} />
      </div>
    </Router>
  );
}

export default App;
