import React, { Component } from 'react';
import CNav from '../components/CNav';
import CFooter from '../components/CFooter';
import CCarousel from '../components/CCarousel';
import { authorimgsource } from '../utils/UConstants';

class PAuthor extends Component {
  render() {
    return (
      <div className="bg bg_author">
        <CNav />
        <div className="page_container page_container_top">
          <h1 className="page_title_hdr">Author</h1>
          <h1 className="page_title_content">
            Dr. Kanada Narahari mainly writes in Kannada. Modala MaLaeya Mannu and Gandakee Zone are
            his two published books of short stories. Apart from fiction writing, Dr. Kanada
            Narahari is a columnist in various newspapers in Kannada.
          </h1>
        </div>
        <div className="carousel_container">
          <CCarousel imgsource={authorimgsource} />
        </div>
        <CFooter />
      </div>
    );
  }
}

export default PAuthor;
