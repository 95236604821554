export const helpOptions = [
  { value: 'chocolate', label: 'Sitarist' },
  { value: 'strawberry', label: 'Medicine' },
  { value: 'vanilla', label: 'Literature' },
];

export const authy = {
  clientid: '',
  clientdomain: '',
};

// Function to retrieve my local ip address
/* const getIp = () => {
  let myIP;
  window.RTCPeerConnection =
    window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection; // compatibility for Firefox and chrome
  const pc = new RTCPeerConnection({ iceServers: [] });
  const noop = function() {};
  pc.createDataChannel(''); // create a bogus data channel
  pc.createOffer(pc.setLocalDescription.bind(pc), noop); // create offer and set local description
  pc.onicecandidate = function(ice) {
    if (ice && ice.candidate && ice.candidate.candidate) {
      myIP = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/.exec(
        ice.candidate.candidate
      )[1];
      // eslint-disable-next-line no-restricted-syntax
      console.log('my IP: ', myIP);
      pc.onicecandidate = noop;
    }
  };
  return myIP;
}; */

// returns current url
export const clientWrapper = () => {
  const hostname = window && window.location && window.location.hostname;
  //   alert(`hostname = ${hostname}`);
  if (hostname === 'localhost') return `http://${hostname}:3000/`;
  return `https://${hostname}/`;
};

// export const client = 'http://192.168.1.2:3000/';
export const client = clientWrapper();

export const sitaristimgsource = [
  {
    srcId: '2QQvMXy2CBI',
    type: 1,
  },
  {
    srcId: '1e1Ctv4hb3o',
    type: 1,
  },
  {
    srcId: 'G-hKl0sO9HQ',
    type: 1,
  },
  {
    srcId: 'aoObbuo9E8c',
    type: 1,
  },
];
export const ayurvedaimgsource = [
  {
    srcId: 'EE50Axp3xqk',
    type: 1,
  },
  {
    srcId: 'H4Mcua4mLxw',
    type: 1,
  },
  {
    srcId: '-0vNbRKvbNQ',
    type: 1,
  },
  {
    srcId: '7DOukidv_Do',
    type: 1,
  },
];
export const authorimgsource = [
  {
    srcId: 'book1.jpg',
    type: 2,
  },
  {
    srcId: 'book2.jpg',
    type: 2,
  },
];
export const articleimgsource = [
  {
    //  srcId: `${client}src/assets/images/music1.png`,
    keyId: 1,
    srcId: 'music1.jpg',
    type: 2,
  },
  {
    keyId: 2,
    srcId: 'music2.jpg',
    type: 2,
  },
  {
    keyId: 3,
    srcId: 'music3.jpg',
    type: 2,
  },
  {
    keyId: 4,
    srcId: 'music4.jpg',
    type: 2,
  },
  {
    keyId: 5,
    srcId: 'music5.jpg',
    type: 2,
  },
  {
    keyId: 6,
    srcId: 'music6.jpg',
    type: 2,
  },
  {
    keyId: 7,
    srcId: 'music7.jpg',
    type: 2,
  },
  {
    keyId: 8,
    srcId: 'music8.jpg',
    type: 2,
  },
  {
    keyId: 9,
    srcId: 'music9.jpg',
    type: 2,
  },
  {
    keyId: 10,
    srcId: 'music10.jpg',
    type: 2,
  },
  {
    keyId: 11,
    srcId: 'music11.jpg',
    type: 2,
  },
  {
    keyId: 12,
    srcId: 'music12.jpg',
    type: 2,
  },
  {
    keyId: 13,
    srcId: 'music13.jpg',
    type: 2,
  },
  {
    keyId: 14,
    srcId: 'music14.jpg',
    type: 2,
  },
  {
    keyId: 15,
    srcId: 'music15.jpg',
    type: 2,
  },
  {
    keyId: 16,
    srcId: 'music16.jpg',
    type: 2,
  },
];

export const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 5, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

export const eventImages = [
  'https://images.unsplash.com/photo-1531058020387-3be344556be6?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
  'https://images.unsplash.com/photo-1478147427282-58a87a120781?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
  'https://images.unsplash.com/photo-1522158637959-30385a09e0da?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80',
  'https://images.unsplash.com/photo-1523580494863-6f3031224c94?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80',
];
export const blogImages = [
  'https://images.unsplash.com/photo-1505751172876-fa1923c5c528?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
  'https://images.unsplash.com/photo-1544367567-0f2fcb009e0b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=794&q=80',
  'https://images.unsplash.com/photo-1515894203077-9cd36032142f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60',
  'https://images.unsplash.com/photo-1495214783159-3503fd1b572d?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
  'https://images.unsplash.com/photo-1490187291333-b4ece7bec2e0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60',
];
